var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"elevation":"1"}},[_c('v-sheet',{staticClass:"d-flex pa-5"},[_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.$router.push({name: 'invoice-new'})}}},[_vm._v(" Nouvelle facture ")])],1),_c('v-spacer'),_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"me-3",attrs:{"dense":"","label":"Chercher facture","placeholder":"Chercher une facture","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-select',{attrs:{"clearable":"","outlined":"","dense":"","items":_vm.statusList,"multiple":"","chips":"","item-text":"text","item-value":"statusName","label":"Filtrer par status"},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}})],1)],1),(_vm.invoices.length != 0)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.invoicesStatusFilter,"search":_vm.search},on:{"dblclick:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('FacturationStatusChipVue',{attrs:{"status":item.status}})]}},{key:"item.student",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.student.prenom)+" "+_vm._s(item.student.nom)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.getFormationName(item.student.formation))+" ")])],1)],1)]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.total).toLocaleString('fr-FR'))+" € ")]}},{key:"item.datefacturation",fn:function(ref){
var item = ref.item;
return [(item.datefacturation)?_c('div',{staticClass:"d-flex flex-column"},[_vm._v(" "+_vm._s(_vm._f("date")(new Date(item.datefacturation),'dd/MM/yyyy'))+" ")]):_c('div',{staticClass:"d-flex flex-column"},[_vm._v(" A facturer ")])]}},{key:"item.datereception",fn:function(ref){
var item = ref.item;
return [(item.datereception)?_c('div',{staticClass:"d-flex flex-column"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.getDateReception(item.datereception))}})]):_c('div',{staticClass:"d-flex flex-column"},[_vm._v(" "+_vm._s(item.status === 'billed' ? 'A venir' : 'Pas encore facturé')+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary","dark":""},on:{"click":function($event){return _vm.goToFile(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiEye)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Voir la facture")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"error","dark":""},on:{"click":function($event){return _vm.triggerDelete(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCan)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Supprimer la facture")])]),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.popmenu),function(menuitem,i){return _c('v-list-item',{key:i,on:{"click":function($event){return _vm.menuClick(menuitem.text, item)}}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"mt-1",attrs:{"small":""},domProps:{"textContent":_vm._s(menuitem.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(menuitem.text)}})],1)],1)}),1)],1),_c('v-icon')]}}],null,true)}):_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"table"}})],1),_c('InvoiceDeleteDialog',{attrs:{"delete-dialog":_vm.deleteDialog,"invoice":_vm.selectedInvoice},on:{"closeDeleteDialog":function($event){_vm.deleteDialog = false},"deletedInvoice":_vm.deletedInvoice}}),_c('InvoiceEditDialog',{attrs:{"invoice-dialog":_vm.invoiceDialog,"edited-index":_vm.editedIndex,"edited-item":_vm.editedItem,"status-list":_vm.statusList},on:{"closeInvoiceDialog":function($event){_vm.invoiceDialog = false},"invoiceUpdated":_vm.invoiceUpdated}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }